/*
 * @Descripttion: 数据源  状态
 * @version: 
 * @Author: bbq
 * @Date: 2020-05-29 10:59:26
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-29 12:29:15
 */

import { hookFactory } from '@platform/template';
import { utils, EditTableStore } from '@platform/table-core';
const { uuidv4, isWrong, getPageSizeStorage } = utils;

class MetaStore extends EditTableStore {
    constructor(props) {
        super(props);
        this.uuid = uuidv4();
        // this.name = 'EditTableStore';
        // 合并一下属性  不然会导致  get 方法取不到值 by bbqin
        this.store = {
            ...this.store,
            allpks: [],
            alltss: [],
            sideBoxConfig: {
                show: false,
                // 就是行的record
                record: {},
                operateType: 'add',
            },
            pageInfo: {
                //pageSize: '10',
                pageIndex: '1',
                total: '0',
                totalPage: '1',
            },
            activeCell: null,
        };
    }

    /*-------------列-------------*/
    /**
    * @description: 设置所有meta
    * @param {type} 
    * @return: 
    */
    setMeta(meta, id, shouldForceUpdate = true) {
        id = id || this.getStore('name');
        let status = this.getTableProps('status');

        // meta上的状态值更新一次 后面都用内部的状态 但是这样也是有BUG的
        if (meta[id] && meta[id].status && !this.isUpdateMetaStatus) {
            status = meta[id].status || status || 'borwse';
            this.isUpdateMetaStatus = true;
        }

        this.setTableProps('status', status, false);
        // console.log('setMeta');
        super.setMeta(meta, id, shouldForceUpdate);
    }

    setTableProps(key, value, shouldForceUpdate = true, callback) {
        // 如果meta上的状态  还没有设置  setTableProps 已经执行  则设置已经更新
        if (key === 'status' && !this.isUpdateMetaStatus) {
            this.tableStatusIsUpdate = true;
        }
        // 拦截处理下特殊情况
        super.setTableProps(key, value, shouldForceUpdate, callback);
    }

    /*-------------缓存-------------*/
    /*-------------私有-------------*/
    /*-------------列-------------*/
    /*-------------单元格-------------*/
    /*-------------行-------------*/
    /*-------------表格-------------*/
    /*-------------高阶组件-------------*/

    /**
     * @description: 获取分页信息
     * @param {type} 
     * @return: 
     */
    getPageInfo() {
        // console.log(this.get(['pageInfo']));
        let prePageSize = getPageSizeStorage(this.getStore(['meta']), this.getStore('name'));
        let pageInfo = this.getStore(['pageInfo']);
        if (!pageInfo.pageSize) {
            pageInfo.pageSize = prePageSize;
        }
        return pageInfo;
    }

    /**
     * @description: 設置分页信息
     * @param {type} 
     * @return: 
     */
    setPageInfo({ shouldForceUpdate = true, pageInfo = {} }) {
        let prevPageInfo = this.get(['pageInfo']) || {};
        prevPageInfo = { ...prevPageInfo, ...pageInfo }
        // console.log(prevPageInfo);
        return this.setStore(['pageInfo'], prevPageInfo, shouldForceUpdate);
    }

    /**
     * @description: 设置表格数据
     * @param {type} 
     * @return: 
     */
    setTableData(data = {}, { isCache = true, isTop = false, shouldForceUpdate = true } = {}) {
        let prePageSize = getPageSizeStorage(this.getStore(['meta']), this.getStore('name'));
        // TODO pageInfo
        let allpks = this.getStore(['allpks']);
        let alltss = this.getStore(['alltss'])
        // 设置分页相关数据
        if (data.hasOwnProperty('pageInfo') && !isWrong(data.pageInfo)) {
            // 前端重置后端返回的页码，初次为0，应该改为1，有问题在看看
            data.pageInfo.pageIndex === '0' ? (data.pageInfo.pageIndex = '1') : '';
        } else if (data.rows.length === 0) {
            data.pageInfo = {
                pageSize: prePageSize,
                pageIndex: '1',
                total: '0',
                totalPage: '1',
            };
        } else {

            if (this && Array.isArray(allpks) && allpks.length > 0) {
                data.pageInfo = this.getPageInfo();
            } else {
                data.pageInfo = {
                    pageSize: prePageSize,
                    pageIndex: '1',
                };
            }
        }

        // 设置分页
        this.setPageInfo({ shouldForceUpdate: false, pageInfo: data.pageInfo })

        // 设置分页的allpks
        if (data.hasOwnProperty('allpks')) {
            this.setStore(['allpks'], data.allpks || allpks || [], false);
        } else if (data.rows.length === 0) {
            this.setStore(['allpks'], [], false);
        } else {
            this.setStore(['allpks'], allpks || [], false);
        }

        if (data.hasOwnProperty('alltss')) {
            this.setStore(['alltss'], data.alltss || alltss || [], false);
        } else if (data.rows.length === 0) {
            this.setStore(['alltss'], [], false);
        } else {
            this.setStore(['alltss'], alltss || [], false);
        }

        data = super.setTableData(data, { isCache, isTop, shouldForceUpdate });
        return data;
    }

    /**
     * @description: 设置表格状态
     * @param {type} 
     * @return: 
     */
    setTableStatus(status, { shouldForceUpdate = true } = {}) {
        super.setTableStatus(status, { shouldForceUpdate });
    }

    /**
     * @description: 删除表格行
     * @param {type} 
     * @return: 
     */
    deleteTableRows({ rowKeyValues, rowIndexs }, thorough, shouldForceUpdate, callback, view) {
        let delItems = super.deleteTableRows({ rowKeyValues, rowIndexs }, thorough, false, callback, view);
        let pageInfo = this.getPageInfo();
        // 这里为了过滤delete之后
        pageInfo && (pageInfo.total -= delItems.length);
        this.setPageInfo({ shouldForceUpdate: false, pageInfo });
        // 处理删除后的勾选逻辑
        let visibleRows = this.getViewData();
        let checkInfo = this.getCheckInfo() || {};
        if (!visibleRows.length) {
            checkInfo.checkedAll = false;
            checkInfo.indeterminate = false;
        } else {
            checkInfo.indeterminate = visibleRows.some(item => !!item.selected);
            checkInfo.checkedAll = visibleRows.every(item => !!item.selected);
        }

        // 更新
        this.setCheckInfo({ checkInfo, shouldForceUpdate, callback });

        return delItems;
    }
    // liumings
    getActiveCell = () => {
        return this.getStore(['activeCell']);
    }
    //liumings
    setActiveCell = (param, shouldForceUpdate = false) => {
        this.setStore(['activeCell'], param, shouldForceUpdate);

    }
    /**
     * @description: 钩子
     * @param {type} 
     * @return: 
     */
    getHooks = () => {
        // console.log(this, super.getHooks());
        // console.log(this);

        return {
            ...this.getTableHooks(),
            getPageInfo: this.getPageInfo.bind(this),
            setPageInfo: this.setPageInfo.bind(this),
            setTableData: this.setTableData.bind(this),
            setTableStatus: this.setTableStatus.bind(this),
            deleteTableRows: this.deleteTableRows.bind(this),
            getActiveCell: this.getActiveCell.bind(this),
            setActiveCell: this.setActiveCell.bind(this),
            setTableProps: this.setTableProps.bind(this),
            // TODO
        };
    };
}

export default hookFactory(MetaStore);