/*
 * @Descripttion: 
 * @version: 0.0
 * @Author: bbq
 * @Date: 2020-05-29 11:00:29
 * @LastEditors: bbq
 * @LastEditTime: 2021-03-05 13:58:48
 */
import React, { Component, Fragment } from 'react';
import { utils, CONFIG, lib, handleSortcolumns } from '@platform/table-core';
import { BaseComponent } from '@platform/template';
import useEditTable from './store';
import { getSysFieldid, getLangCode, getMultiLang, pageTo } from "@platform/api";
import { Table } from '@platform/base';
import sidebox from './components/SideBox/hoc';

const { formatColumn, hotkey, pagination, selectRow, indexCol, checkCol, errorCol, filter, selectable, excel, total, columnSort, contextMenu, hoverContent } = lib;
const { uuidv4, excelPackageData, isString, isFunction, isArray, isObject, isEmptyValue, polymerizeComponents, isUndefined, warningOnce, getMetaIsTotal, setPageSizeStorage } = utils;
const { ROW_STATUS } = CONFIG;
// selectRow(pagination(formatColumn(Table)))
const MetaTable = polymerizeComponents(
    Table,
    // 高阶组件是有顺序的
    [
        columnSort,
        formatColumn,
        // 不然操作列  checkbox 列会显示在侧拉内部
        sidebox,
        total,
        hoverContent,
        pagination,
        selectRow,
        errorCol,
        checkCol,
        indexCol,
        filter,
        selectable,
        excel,
        hotkey,
        contextMenu,

    ],
    {
        name: 'editTable',
        type: 'hoc',
        user: 'platform',
    });

require('./index.less');

export default class EditTable extends BaseComponent {

    constructor(props) {
        super(props);

        let { store, tableId, rowKey = 'rowid', status } = props;
        this.state = { isEllipsis: true, key: 1, langJson: {} };
        // 设置一些属性
        store.setTableProps('rowKey', rowKey, false);
        // rowKey 更新之后要更新  数据不然会导致数据报错
        // store.setData(store.getData(), false);
        store.setTableProps('status', status || store.getTableProps('status') || 'browse', false);
        store.setStore('name', tableId, false);

        this.uuid = uuidv4();
        typeof window === 'object' && (window[this.uuid] = store);

        this.isClickedCheckCol = false;
    }

    UNSAFE_componentWillMount() {
        let { store, afterLangload } = this.props;

        let { setCache } = store;

        //加载多语
        let callback = json => {
            // this.setState({ json });
            setCache('langJson', json || {});
            // console.log(store.getCache('langJson'))
            // 备份一份到全局
            afterLangload && afterLangload(json, store);

            // 强制更新一次
            // this.forceUpdate();
            this.setState({ langJson: json });
        };
        getMultiLang({ moduleId: "page_table", callback });
    }

    componentDidUpdate(nextProps, nextState) {
        let { editTableComponentUpdate } = this.props;
        editTableComponentUpdate && editTableComponentUpdate(this.props);
    }

    pageInfoChange = value => {
        const { onPageInfoChange, store, pkname, crossPageSelect, tableId } = this.props
        let pageInfo = store.getPageInfo();
        pageInfo = { ...pageInfo, ...(value || {}) }
        let allpks = store.getStore(['allpks']);

        setPageSizeStorage(store.getStore(['meta']), tableId, pageInfo.pageSize);

        store.setPageInfo({ pageInfo });
        let pks = this.splitPks(allpks, pageInfo.pageIndex, pageInfo.pageSize);
        console.log(pks, value);
        isFunction(onPageInfoChange) && onPageInfoChange(pks, pageInfo.total)
    }

    // 分割分页id
    splitPks = (data, pageIndex, pageSize) => {
        if (data.length <= pageSize) {
            // 这里暂时解决问题，后续在进行优化
            return data;
        }
        let pks = [];
        let index = Number(pageIndex);
        let size = Number(pageSize);
        let start = (index - 1) * size;
        for (let i = start; i < start + size; i++) {
            if (data[i]) {
                pks.push(data[i]);
            } else {
                break;
            }
        }
        return pks;
    }

    onAllCheckChange = value => {
        let { store, selectedChange, onSelectedAll, tableId, crossPageSelect, pkname } = this.props;
        let { setRowsSelected, getSelectedRows, getCheckInfo, getData, setCrossPage } = store;
        let { checkedAll } = getCheckInfo() || {};
        let oldLen = getSelectedRows(false, 'filter').length;
        checkedAll = !checkedAll;

        setRowsSelected({}, checkedAll, true, { triggerType: 'event' });
        // 跨页全选
        if (crossPageSelect && pkname) {
            let rows = getData()
            setCrossPage(rows, pkname, value)
        }
        let newLen = getSelectedRows(false, 'filter').length;

        isFunction(onSelectedAll) && onSelectedAll(tableId, checkedAll, getData().length);
        isFunction(selectedChange) && selectedChange(tableId, newLen, oldLen)
    }

    // 框选等操作
    onBatchSelected(rows = [], hotkey) {
        let { onBatchSelected, store, crossPageSelect, pkname } = this.props
        let { getTableProps, setRowsSelected, getRowProps, getSelectedRows, setTableProps, getTrueRowIndex, setCrossPage } = store;
        let rowKey = getTableProps('rowKey');

        // 清空所有
        setRowsSelected({}, false, false);

        rows.forEach(row => {
            let rowKeyValue = row[rowKey];
            let selected = !getRowProps({ rowKeyValue }, 'selected');

            let values = row.values || row,
                isSagaStatus = values && values.saga_status && values.saga_status.value === '1';
            // 错误行禁用
            if (isSagaStatus) {
                return;
            }

            // 跨页全选
            if (crossPageSelect && pkname) {
                setCrossPage(row, pkname, selected);
            }

            setRowsSelected({ rowKeyValues: rowKeyValue }, selected, false);

            // setTableProps('currentIndex', getTrueRowIndex({ rowKeyValue }), false);
        });
        // 更新
        this.forceUpdate && this.forceUpdate();
        // console.log(rows);
        onBatchSelected && onBatchSelected(rows)
    }

    onCheckChange = (record, index, isUnique = false) => {
        let { selectedChange, onSelected, tableId, store, crossPageSelect, pkname } = this.props
        let { getTableProps, setRowsSelected, getRowProps, getSelectedRows, setTableProps, getTrueRowIndex, setCrossPage, getStore } = store;
        const { checkInfo } = getStore()
        let { crossPageSelectIndex } = checkInfo
        let rowKey = getTableProps('rowKey');
        let rowKeyValue = record[rowKey];
        let selected = !getRowProps({ rowKeyValue }, 'selected');
        let oldLen = getSelectedRows(false, 'filter').length;
        // 跨页全选
        if (crossPageSelect && pkname) {
            if (isUnique) {
                crossPageSelectIndex.clear()
            }
            setCrossPage(record, pkname, selected)
        }

        if (isUnique) { // 清理所有
            setRowsSelected({}, false, false);
            // 跨页全选会影响这里 TODO
        }

        // 特殊情况 如果当前选择 选择行时 依然选中
        if (!selected && isUnique && oldLen > 1) {
            selected = true;
        }

        setRowsSelected({ rowKeyValues: rowKeyValue }, selected);

        setTableProps('currentIndex', selected ? getTrueRowIndex({ rowIndex: index }, 'normal', 'filter') : -1, false);

        let newLen = getSelectedRows(false, 'filter').length;
        // 选中选项的回调函数
        isFunction(onSelected) && onSelected(tableId, record, selected, index, isUnique);
        isFunction(selectedChange) && selectedChange(tableId, newLen, oldLen)
    }

    changeCrossSelect = value => {
        const { store, pkname, selectedChange, tableId } = this.props;
        const { getStore, setStore, getData, _setSelect, setCrossPage } = store
        const { checkInfo, allpks } = getStore()
        let { crossPageSelectIndex } = checkInfo
        if (!pkname) { warningOnce(false, `请配置pkname`); return }
        let oldLen = crossPageSelectIndex.size
        let rows = getData()
        switch (value) {
            case 'current':
                _setSelect(undefined, 'all')
                crossPageSelectIndex.clear()
                setCrossPage(rows, pkname, true)
                break;
            case 'all':
                _setSelect(undefined, 'allpage')
                crossPageSelectIndex = new Set(allpks)
                break;
            case 'reverse':
                _setSelect(undefined, 'reverse')
                allpks.forEach(pk => {
                    if (crossPageSelectIndex.has(pk)) {
                        crossPageSelectIndex.delete(pk)
                    } else {
                        crossPageSelectIndex.add(pk)
                    }
                })
        }
        setStore(['checkInfo', 'crossPageSelectIndex'], crossPageSelectIndex)
        let newLen = crossPageSelectIndex.size
        isFunction(selectedChange) && selectedChange(tableId, newLen, oldLen)
    }

    sortChange = (sortParam, sortData) => {
        let sortObj = {};
        let { store, tableId } = this.props;
        let { setStore, getViewMeta } = store;
        /**
         * 每次排序都存储新的排序信息
         * 这是产品李聪慧让加的, 为了解决单据打印时，列表对中字段排序产生的问题
         */
        setStore(['sortParam'], {
            mode: "single",
            sortParam,
        }, false);
        sortParam.forEach(item => {
            sortObj[item.field] = item;
        });
        // 处理排序多表头
        handleSortcolumns({
            columns: getViewMeta(tableId),
            sortObj,
        });

        let invisibleData = store.getViewData('invisible') || [];

        // console.log(invisibleData, 'invisibleDatainvisibleDatainvisibleDatainvisibleDatainvisibleDatainvisibleDatainvisibleData');

        store.setData({ data: [...sortData, ...invisibleData] });
    }
    getColSettiongParam = () => {
        let { store, tableId } = this.props
        let meta = store.getMeta()
        if (meta && meta[tableId]) {
            let currentMeta = meta[tableId]
            let config = store.getConfig()
            return {
                appcode: currentMeta.appcode || pageTo.getAppCode(),
                pagecode: currentMeta.pagecode || pageTo.getPageCode(),
                code: currentMeta.templateCode || meta.code,
                pageid: currentMeta.pageid || meta.pageid,
                areaCode: tableId,
                areaId: currentMeta.oid,
                noColSetClass: config.noColSetClass,
                colSetCallback: config.colSetCallback,
                hiddenLeftItem: config.hiddenLeftItem,
            }
        } else { return {} }
    }
    updateColumnAfterSetting = (newColumn, areaCode) => {
        let { store } = this.props;
        let { setMeta, getMeta } = store;
        let meta = getMeta()
        if (newColumn.length && areaCode) {
            let finalColumn = [...meta[areaCode].items];

            let sortIndexArr = [];
            let sortObjArr = [];
            newColumn.map(newItem => {
                meta[areaCode].items.map((oldItem, index) => {
                    if (newItem.attrcode === oldItem.attrcode) {
                        oldItem.width = newItem.width;
                        oldItem.islock = newItem.islock;
                        oldItem.visible = newItem.visible;
                        sortIndexArr.push(index);
                        sortObjArr.push(oldItem);
                    }
                });
            });
            sortIndexArr.sort((a, b) => a - b);
            sortIndexArr.map((item, index) => {
                finalColumn[item] = sortObjArr[index];
            });
            meta[areaCode].items = finalColumn;
            setMeta(meta, areaCode)
            // this.setState({ key: this.state.key + 1 })
        }
    }

    //右键菜单点击函数
    contextMenuClick = (btnCode, execCommandCopy, param, insertNum, rowIndex, isHotKey = false) => {
        let { store, rightMenuClick, onBatchChange, langJson, tableId } = this.props;
        let { getData, getRowOldValue, insertRowsAfterIndex, deleteTableRows, getViewData } = store;
        let editCode = ["delete", 'insert_up', 'insert_down'];
        let copyCode = ["copy", "copy-row", "copy-column"]
        switch (true) {
            case editCode.includes(btnCode):
                if (isFunction(rightMenuClick)) {
                    //删行、插行、插行并粘贴等右键按钮需要业务适配
                    if (btnCode === 'insert_up') {
                        let rows = Array(insertNum).fill({ values: {} });
                        insertRowsAfterIndex(rows, rowIndex - 1);
                    }
                    if (btnCode === 'insert_down') {
                        let rows = Array(insertNum).fill({ values: {} });
                        insertRowsAfterIndex(rows, rowIndex);
                    }
                    if (btnCode === 'delete') {
                        deleteTableRows({ rowIndexs: rowIndex })
                    }
                    rightMenuClick(btnCode, insertNum, rowIndex);
                }
                break;
            case copyCode.includes(btnCode):
                //选中表头
                let activeHeads = store.getCache('activeSpecialCells');
                console.log("表头选中信息：", activeHeads)
                let arrayMeta = store.getArrayMeta(tableId);
                let currentMeta = store.getViewMeta(tableId)
                let activedCells = store.getCache("activedCells");

                // console.log(activedCells, activeHeads);
                if (isHotKey && !activedCells && !activeHeads) {
                    return;
                }

                let selectedRows = [];
                if (btnCode === 'copy' || btnCode === 'copy-row') {
                    selectedRows = store.getRows({ rowKeyValues: Object.keys(activedCells) });
                } else if (btnCode === 'copy-column') {
                    selectedRows = store.getData([ROW_STATUS.delete, ROW_STATUS.filter]);
                }

                isFunction(execCommandCopy) && execCommandCopy(activeHeads, currentMeta, activedCells, selectedRows, arrayMeta);
                break;
            case btnCode === "paste":
                //调用复制粘贴回调事件
                if (isFunction(onBatchChange)) {
                    param = {
                        ...param,
                        langJson: langJson,
                        rows: getData(),
                        viewData: getViewData(),
                        getRowOldValue: getRowOldValue,
                        tableId: tableId,
                        store: store,
                    }
                    let data = excelPackageData(param);
                    onBatchChange(data);
                }
                break;
            case btnCode === "insert_paste":
                //插行并粘贴
                if (isFunction(onBatchChange)) {
                    let rows = Array(insertNum).fill({ values: {} });
                    insertRowsAfterIndex(rows, rowIndex);
                    //插入空行
                    param = {
                        ...param,
                        langJson: this.props.langJson,
                        rows: getData(),
                        viewData: getViewData(),
                        getRowOldValue: getRowOldValue,
                        tableId: tableId,
                        store: store,
                    }
                    let data = excelPackageData(param);
                    onBatchChange(data);
                }
                break;
        }
    };

    render() {
        let {
            // 表格UE'
            rowKey,
            store,
            status,
            editType,
            langJson = {},
            autoAddRow = {},
            tableId,
            // tinper底层
            bodyDisplayInRow,
            lazyload = true,
            columns,
            rowClassName,
            // 高阶组件
            hideBrowseCheckbox,
            showCheck, showIndex,
            crossPageSelect,
            high_filter = {},
            handleFilterCallBack,
            handleResetCallBack,
            high_selectable = {},
            onBatchSelected,
            showPagination,
            // 常见回调
            onRowClick, onBeforeEvent, onAfterEvent, onChange, onRowDoubleClick,
            // 弹窗
            onCloseModel, fieldid, tableModelConfirm,
            onCellHyperlinkTo,
            showTotal, noTotalRows, noTotalCol, allowTotalRows, //合计行配置
            onBatchChange,
            noColSetClass, colSetCallback, cancelCustomRightMenu = false, //列设置
            addBlankCol,
            showWidthAndHeightConfig = true,
            multipleRowCell, //折行
            bodyExpandAllInRow = false, // 全部展开
            otherAreaHeight = 0, //高度自适应参数
            rightMenuClick,
            focusIndex,
            foldCacheId, //对于没有appcode，pagecode..的表格，做折行缓存的标志
            hideRightMenu = false,
            isSort = true,
            disableRowClickSelect = false,
            ...others
        } = this.props;

        const {
            getMeta, getViewMeta,
            getCache, getStore,
            getData, getTableProps,
            getViewData,
            setTableProps,
            getTrueRowIndex,
            getRowKeyValue, setSelectedIndex, hasSelected,
            getCellProps, setCellProps, setCellValue, getCellValue,
            getPageInfo,
            getCheckInfo,
            getActiveCell, setActiveCell,
        } = store;

        if (hideRightMenu) {
            cancelCustomRightMenu = true;
            high_selectable.enable = false;
            showWidthAndHeightConfig = false;
        }

        // 表格状态
        status = getTableProps('status');
        // 多语
        langJson = { ...(this.state.langJson || {}), ...(getCache('langJson') || {}) };
        // console.log(langJson, 'langJsonlangJsonlangJsonlangJsonlangJson');
        // rowKey
        rowKey = getTableProps('rowKey');
        // columns
        columns = getViewMeta(tableId) || columns;
        if (!columns || !columns.length) {
            return null;
        }
        // arrcolumns
        let arrColumns = store.getArrayMeta(tableId);
        // data
        let data = getData([ROW_STATUS.delete, ROW_STATUS.filter]);
        // filterData
        let filterData = getViewData('filter');
        // 
        let customColData = getCache('customColData');
        // metaName
        let metaName = getStore(['meta', tableId, 'name']);
        // console.log(metaName, getCheckInfo());
        let backSort = store.getConfig().sort;
        // console.log(getTableProps('rowKey'))
        // 合计
        let isTotal = showTotal || getMetaIsTotal(arrColumns);
        // 列设置参数
        let colSettingParam = this.getColSettiongParam();
        // sidebox
        const sideBoxConfig = getStore('sideBoxConfig') || {};
        // focusInex
        focusIndex = focusIndex || getTableProps('focusIndex');

        // 默认开启 框选 high_selectable.enable
        //high_selectable.enable = high_selectable.enable || true;

        // 默认启用快捷键选中 只有快捷键选中行
        if (isUndefined(high_selectable.enable)) {
            high_selectable.enable = true;
            // high_selectable.disabled = isUndefined(high_selectable.disabled) ? 'slide' :
            //     (isArray(high_selectable.disabled) ? high_selectable.disabled : [high_selectable.disabled]);
            // high_selectable.single = high_selectable.single || false;
            // // 不开启
            // high_selectable.selectCell = isUndefined(high_selectable.selectCell) ? false : high_selectable.selectCell;
        }
        // console.log(high_selectable);
        // 是否框选(批选)
        const isBatchSelect = isUndefined(high_selectable.selectCell) ? true : high_selectable.selectCell;

        // if(status !== 'browse'){
        //     high_selectable.enable = false;
        // }

        return <div
            className="meta-table-wrapper edittable lightapp-component-editTable"
            data-uuid={this.uuid}
        // fieldid={getSysFieldid(`${fieldid || tableId}_table`)}
        // ref="table"
        >
            <MetaTable
                lazyload={lazyload}
                focusIndex={focusIndex}
                rowKey={rowKey}
                status={status}
                editType={editType}
                columns={columns}
                fieldid={fieldid}
                bodyDisplayInRow={isUndefined(multipleRowCell) ? this.state.isEllipsis : !multipleRowCell}
                bodyExpandAllInRow={bodyExpandAllInRow}
                // 单元格是否多行显示
                data={data}
                store={store}
                isTotal={isTotal}
                getCellValue={getCellValue}
                showPagination={status !== 'edit' && showPagination}
                otherAreaHeight={(showPagination && status !== 'edit') ? 42 + otherAreaHeight : otherAreaHeight}
                cardType="edit"
                onCellHyperlinkTo={(record, attrcode, value) => {
                    onCellHyperlinkTo && onCellHyperlinkTo(record, attrcode, value, tableId);
                }}
                //是否需要排序
                isSort={isSort}
                backSort={backSort}
                sortChange={this.sortChange} //排序的回调
                getTrueRowIndex={(rowKeyValue, index, record) => {
                    index = getTrueRowIndex({ rowKeyValue }, 'normal', 'filter');
                    return index;
                }}
                setCellValue={params => {
                    let {
                        rowKey, rowKeyValue, rowIndex,
                        attrcode, column, value = {},
                        triggerType, // onChange || onSelect ...
                    } = params;

                    // 因为timepicker的value特殊，所以特殊转换一下start，有问题在看一下
                    if (column.itemtype === 'timepicker') {
                        if (value.value && value.value.format) {
                            value.value = value.value.format('hh:mm:ss');
                        }
                    }

                    // 适配多语 //这是最新适配的多语  也可以放在 setCellValue 里  TODO
                    setCellValue({ rowKey, rowKeyValue, rowIndex, attrcode, value }); // 这里就是onChange 和 onBlur 的集合

                    let next = isFunction(onChange) && (onChange(params) !== false);
                    if (!next) { return; }

                    // 数值类型的实时校验 TODO 需要找轩轩进行适配
                    // let flag = isInputType
                    //     ? item.itemtype !== 'number'
                    //         ? foolValue.target
                    //             ? foolValue.target.isFlag
                    //             : false
                    //         : false
                    //     : false;
                    // if (!flag && item.maxlength && isInputType && valueChange && foolValue) {

                    //     if (!flag && valueChange.length > item.maxlength) {
                    //         valueChange = rows[index].values[item.attrcode].value;
                    //         toast({
                    //             color: 'danger',
                    //             title: `${json && json['table_tips']}`,
                    //             // content: `${json && json['table_tips_content']}${item.maxlength / 2}${json && json['table_maxlength_unit']}`
                    //             content: `${json && json['table_tips_content']}${item.maxlength}${json && json['table_maxlength_unit']}`
                    //         });
                    //         foolValue.target && foolValue.target.blur();
                    //     }

                    //     rows[index].values[item.attrcode].value = valueChange;
                    // }

                    // 老版的批改 TODO  分为多语和非多语两种情况
                    // 处理单元格变化的数据zhnghengh
                    // let theValue = handleChangeData(item.itemtype === 'refer', foolValue, valueChange, item);
                    if (column.itemtype !== 'residtxt') {
                        let isMulti = column.isMultiSelectedEnabled;
                        store.setCache('oldBatchData', {
                            // TODO  转换为过滤后的index
                            // 在onchange时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                            batchChangeIndex: rowIndex,
                            batchChangeKey: column.attrcode,
                            batchChangeValue: value.value,
                            batchChangeDisplay: value.display,
                            // batchChangeValue: isMulti ? theValue : theValue.value,
                            // batchChangeDisplay: isMulti ? null : theValue.display
                        });
                    } else {
                        // 多语批改
                        store.setCache('oldBatchData', {
                            batchChangeIndex: rowIndex,
                            batchChangeKey: column.attrcode,
                            batchChangeValue: value.value,
                            batchChangeDisplay: null,
                        });
                    }

                    // 编辑关联项  放在 onAfterEvent 中

                    // 把status置为1，标识修改     状态不为1的不动 (比如用户自己set但是状态为2的)
                    let rowStatus = store.getRowProps({ rowKeyValue }, 'status');
                    if (rowStatus == ROW_STATUS.origin) {
                        store.setRowProps({ rowKeyValue }, { status: ROW_STATUS.edit })
                    }
                }}
                getCellProps={({ rowKey, rowKeyValue, rowIndex, attrcode }) => {
                    return getCellProps({ rowKey, rowKeyValue, rowIndex, attrcode });
                }}
                // 给自动话用以及给底层cell快捷键等组件用
                name={tableId}
                tableId={tableId}
                onBeforeEvent={async params => {
                    let {
                        record, rowKey, rowKeyValue, rowIndex,
                        attrcode, column, value = {},
                        event,
                        triggerType, // onClick || onFocus
                    } = params;

                    // 用于解决点击前赋值不上的问题
                    setTableProps("currentIndex", getTrueRowIndex({ rowIndex }, 'normal', 'filter'), false);
                    setTableProps("currentInfo", { record, index: rowIndex }, false);

                    // 检验输入字符长度
                    if (triggerType === 'onFocus' && column.maxlength) {
                        event.target.isFlag = false;
                        event.target.addEventListener(
                            'compositionstart',
                            e => {
                                e.target.isFlag = true;
                            },
                            false,
                        );
                        event.target.addEventListener(
                            'compositionend',
                            e => {
                                e.target.isFlag = false;
                                let flag = e.target.isFlag;
                                let val = e.target.defaultValue;
                                // if (!flag && val.length > item.maxlength) {
                                //     // val = val.substring(0, count);
                                //     val = val.substring(0, item.maxlength);
                                //     rows[index].values[item.attrcode].value = val;
                                //     toast({
                                //         color: 'danger',
                                //         title: `${json && json['table_tips']}`,
                                //         // content: `${json && json['table_tips_content']}${item.maxlength / 2}${json && json['table_maxlength_unit']}`
                                //         content: `${json && json['table_tips_content']}${item.maxlength}${json && json['table_maxlength_unit']}`
                                //     });
                                //     e.target && e.target.blur();
                                // }
                            },
                            false,
                        );
                        // TODO this.setState({ table: this.state.table });
                    }
                    // 多语控件都不走这里
                    if (column.itemtype !== 'residtxt') {
                        // onFocus 和value  用于批量更改   zhanghengh 18/6/29 先注释，以后可能有用

                        store.setCache('oldBatchData', {
                            // TODO  转换为过滤后的index
                            // 在onchange时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                            batchChangeIndex: rowIndex,
                            batchChangeKey: column.attrcode,
                            batchChangeValue: value.value,
                            batchChangeDisplay: value.display,
                            // batchChangeValue: isMulti ? theValue : theValue.value,
                            // batchChangeDisplay: isMulti ? null : theValue.display
                        });
                    } else {
                        // 这里批改多语存值可能没用，之后在看一下，先留着
                        // 多语批改
                        // 这里是多语文本的编辑显示逻辑
                        // attrcode字段对应的value是主语言
                        // 显示规则是有登陆语言显示登陆语言，登陆语言通过getLangCode获得，没有登陆语言显示主语言，attrcode字段对应的value，主语言语言在没有，就什么也不显示
                        // index为1比较特殊 取value[attrcode]
                        let loginCode = getLangCode(),
                            loginIndex = column.languageMeta.find(e => e.languageCode === loginCode)?.index || '',
                            loginValue = value[attrcode + loginIndex] || value[attrcode] || value;
                        store.setCache('oldBatchData', {
                            batchChangeIndex: rowIndex,
                            batchChangeKey: column.attrcode,
                            batchChangeValue: loginValue.value,
                            batchChangeDisplay: null,
                        });
                    }

                    // 有一些操作要放在这里处理 设置行状态等等
                    if (isFunction(onBeforeEvent)) {
                        // setActiveCell(params)
                        return (await onBeforeEvent(params)) !== false;
                    }
                    return true;
                }}
                onAfterEvent={params => {
                    let {
                        record, rowKey, rowKeyValue, rowIndex,
                        attrcode, column, value = {},
                        oldValue = {},
                        componentValue,
                        event,
                        triggerType = 'onChange', // onChange || onBlur
                        isTriggerModal = false,
                    } = params;

                    // console.log('onAfterEvent trigger')
                    // 多语 获取当前的code
                    if (column.itemtype === 'residtxt' && isObject(componentValue)) {
                        for (let key in componentValue) {
                            if (componentValue[key].current) {
                                // value.value = componentValue[key];
                                // oldValue.value = store.getRowOldValue(record[rowKey], key);
                            }
                        }
                    }

                    // 新旧值
                    oldValue.value = oldValue.value || store.getRowOldValue(record[rowKey], attrcode);

                    if (triggerType === 'onBlur') {
                        /*
                        * onBlur编辑后 和 切换控件状态
                        * 1、input类型    line   编辑后 + 切状态
                        *                model  编辑后
                        * 2、uninput类型  line   切状态
                        *                model  无编辑后  无切状态
                        */

                        // 这一段可以考虑去除  输入 类型
                        if (isString(value.value)) {
                            value.value = value.value.trim();
                        }

                        // 当数值类型只输入一个'-'的时候就将它赋值成0
                        if (column.itemtype === 'number' && value.value === '-') {
                            value.value = 0;
                            store.setCellValue({ rowKeyValue, attrcode, value });
                        }

                        // 侧拉里面的状态
                        if (column.itemtype === 'select' && isTriggerModal) {
                            store.setCellProps(rowKeyValue, attrcode, { open: false });
                        }

                        if (column.itemtype === 'residtxt') {
                            if (isTriggerModal) {
                                // 当侧拉的情况下
                                // 多语  登陆语言值 赋值给主语言
                                // tableModeldata.values[item.attrcode] = {
                                //     ...tableModeldata.values[item.attrcode],
                                //     value: val[item.attrcode].value
                                // };
                            } else {
                                // 当表体的情况下

                            }
                            // 多语  登陆语言值 赋值给主语言
                            // 主语言没值时，取登录语言的值
                            if (!record.values[attrcode]?.value) {
                                let loginCode = getLangCode(),
                                    loginIndex = column.languageMeta.find(e => e.languageCode === loginCode)?.index || '',
                                    loginValue = componentValue[attrcode + loginIndex] || componentValue[attrcode];
                                setCellValue({ rowKey, rowKeyValue, rowIndex, attrcode, value: loginValue });
                            }
                        }

                        // 多语控件都不走这里
                        if (column.itemtype !== 'residtxt') {
                            // 在onblur时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                            // pageScope.batchData[moduleId] = {
                            //     batchChangeIndex: outputIndex,
                            //     batchChangeKey: item.attrcode,
                            //     batchChangeValue: record.values[item.attrcode] ? record.values[item.attrcode].value : '',
                            //     batchChangeDisplay: record.values[item.attrcode] ? record.values[item.attrcode].display : ''
                            // };
                        } else {
                            // 多语批改
                            // 这里是多语文本的编辑显示逻辑
                            // attrcode字段对应的value是主语言
                            // 显示规则是有登陆语言显示登陆语言，登陆语言通过getLangCode获得，没有登陆语言显示主语言，attrcode字段对应的value，主语言语言在没有，就什么也不显示
                            // const cellData = record.values[item.attrcode];
                            // let value = isObj(cellData) ? (cellData.value ? cellData.value : null) : null;
                            // const LangCode = getLangCode();
                            // const loginLang = item.languageMeta.filter(i => i.languageCode == LangCode);
                            // if (loginLang.length > 0) {
                            //     loginLang[0].index === '1' ? (loginLang[0].index = '') : '';
                            //     if (
                            //         record.values[item.attrcode + loginLang[0].index] &&
                            //         record.values[item.attrcode + loginLang[0].index].value
                            //     ) {
                            //         value = record.values[item.attrcode + loginLang[0].index].value;
                            //     }
                            // }
                            // pageScope.batchData[moduleId] = {
                            //     batchChangeIndex: outputIndex,
                            //     batchChangeKey: item.attrcode,
                            //     batchChangeValue: value,
                            //     batchChangeDisplay: null
                            // };
                        }
                    }

                    // 设置状态  放在  onAfterEvent 事件之前 避免onAfterEvent 修改record报错
                    store.setCellProps(rowKeyValue, attrcode, { isEdit: false }, false);
                    let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                    // console.log(cellObj, 'cellObjcellObjcellObjcellObjcellObjcellObjcellObjcellObj');
                    cellObj && cellObj.setState({});

                    // 有一些操作要放在这里处理 设置行状态等等
                    let next = isFunction(onAfterEvent) && (onAfterEvent(params) !== false);

                    // 多语 获取当前的code
                    if (column.itemtype === 'residtxt' && isObject(componentValue)) {
                        for (let key in componentValue) {
                            store.saveRowOldValue(record[rowKey], key, componentValue[key].value);
                        }
                    } else {
                        store.saveRowOldValue(record[rowKey], attrcode, value.value);
                    }

                    if (!next) {
                        console.log('not next to run');
                        return false;
                    }

                    /**
                     * 自动增行
                     * 增一行的条件：
                     * 1、最后一行
                     * 2、isAddRow 为true
                     * 3、当前单元格值不为空
                     */

                    let viewLen = store.getViewData().length;
                    let isSwitch_browseDisabled = column.itemtype === 'switch_browse' && column.disabled; // 开关且不可编辑

                    let validValue = column.itemtype === 'residtxt' ? value : value.value;
                    validValue = column.itemtype === 'refer' ? componentValue : value;
                    const isEmpty = isEmptyValue(validValue, column.itemtype);
                    const tableStatus = getTableProps('status');
                    if (
                        viewLen == rowIndex + 1 &&
                        autoAddRow.enabled &&
                        !isSwitch_browseDisabled &&
                        !isEmpty &&
                        tableStatus === 'edit'
                    ) {
                        // 添加自动增行后的回调
                        store.addTableRow(undefined, autoAddRow.defaultValue, true, {
                            callback: autoAddRow.callback,
                            isAutoAddRow: true,
                        });
                    }

                }}
                // 点击单元格，单元格的浏览态编辑态切换时的回调
                onCellStatusChange={params => {
                    let { isEdit, column } = params
                    if (isEdit) {
                        setActiveCell(params)
                    } else {
                        let cell = getActiveCell()
                        if (cell) {
                            let { oldColumn = {} } = cell
                            if (column.attrcode === oldColumn.attrcode) {
                                setActiveCell(null)
                            }
                        }
                    }
                }}
                onCellMouseDown={(record, rowIndex, attrcode, column) => {
                    let booleanType = ['switch', 'checkbox_switch']
                    if (status === 'edit' && booleanType.includes(column.itemtype)) {
                        let rowKey = store.getTableProps('rowKey');
                        let rowKeyValue = record[rowKey];
                        let params = { column, rowIndex, attrcode, rowKeyValue }
                        setActiveCell(params)
                    }
                }}
                rowClassName={(record, current) => {
                    let newRowClassName = rowClassName;

                    if (isFunction(newRowClassName) || isArray(newRowClassName)) {
                        current = store.getTrueRowIndex({ rowIndex: current }, 'normal', 'filter');
                    }

                    let currentCls = [];
                    if (isArray(newRowClassName)) {
                        currentCls = newRowClassName.filter(item => {
                            if (!isArray(item.index)) {
                                item.index = [item.index];
                            }
                            return item.index.includes(current);
                        });
                        newRowClassName = currentCls.reduce((total, item) => { return total + ' ' + item.className }, '');
                    }
                    // console.log(currentCls);
                    return isFunction(newRowClassName) ? newRowClassName(record, current) : newRowClassName;
                }}
                hasSelected={({ rowKey, rowKeyValue, rowIndex }) => {
                    return hasSelected(rowKeyValue);
                }}
                setSelectedIndex={({ record, rowKey, rowIndex }, e) => {
                    // 为了避免和新开发的行框选点选区域选事件冲突
                    if (high_selectable && high_selectable.enable && e && (e.ctrlKey || e.shiftKey || e.metaKey)) {
                        return;
                    }
                    let rowKeyValue = record[rowKey];
                    let values = record.values || record;
                    // 错误行禁用
                    if (values && values.saga_status && values.saga_status.value === '1') {
                        return;
                    }
                    //点击行即勾选复选框
                    if (status !== 'edit' && showCheck && !record.disabled) {
                        if (disableRowClickSelect) {
                            //若disableRowClickSelect为true,点击行时，同参照逻辑：未点过复选框是单选，点过复选框是多选
                            if (!this.isClickedCheckCol) {
                                this.onCheckChange(record, rowIndex, true);
                            }
                        } else {
                            this.onCheckChange(record, rowIndex, true);
                        }
                    } else {
                        let isSelected = hasSelected(rowKeyValue);
                        !isSelected && setSelectedIndex([], 'clear', false);
                        !isSelected && setSelectedIndex([{ rowKey, rowKeyValue, rowIndex, pattern: 'add' }]);
                    }
                }}
                pageInfo={getPageInfo()}
                pageInfoChange={this.pageInfoChange}
                onRowClick={(record, index, e) => {
                    // 为了避免和新开发的行框选点选区域选事件冲突
                    if (high_selectable && high_selectable.enable && e && (e.ctrlKey || e.shiftKey || e.metaKey)) {
                        return;
                    }
                    // 错误行禁用
                    let values = record.values || record;
                    if (
                        !values ||
                        !values.saga_status ||
                        values.saga_status.value !== "1"
                    ) {
                        setTableProps("currentIndex", getTrueRowIndex({ rowIndex: index }, 'normal', 'filter'), false);
                        setTableProps("currentInfo", { record, index }, false);
                    }

                    // console.log(store);
                    isFunction(onRowClick) && onRowClick(record, index, e);
                }}
                onRowDoubleClick={(record, index, e) => {
                    // 兼容筛选
                    // let outputIndex = index;
                    // this.filterAllData && (outputIndex = this.filterAllData.findIndex(row => row.rowid === record.rowid));
                    // 错误行禁用
                    let values = record.values || record;
                    if (
                        !values ||
                        !values.saga_status ||
                        values.saga_status.value !== "1"
                    ) {
                        setTableProps("currentIndex", getTrueRowIndex({ rowIndex: index }, 'normal', 'filter'), false);
                        setTableProps("currentInfo", { record, index }, false);
                    }
                    // 行双击的方法 判断配置文件是否有，并且config.onRowDoubleClick是否是函数  zhanghengh 18/5/8
                    isFunction(onRowDoubleClick) && onRowDoubleClick(record, index, e);

                }}
                checkColConfig={{
                    showCheck: isUndefined(hideBrowseCheckbox) ? showCheck : (status === 'browse' ? !hideBrowseCheckbox : showCheck),
                    crossPageSelect,
                    changeCrossSelect: this.changeCrossSelect,
                    langJson,
                }}
                totalConfig={{
                    showTotal: showTotal,
                    noTotalRows: noTotalRows,
                    noTotalCol: noTotalCol,
                    allowTotalRows: allowTotalRows,
                    numberPd: 7,
                }}
                indexColConfig={{ showIndex: showIndex || false, langJson }}
                onAllCheckChange={value => {
                    this.isClickedCheckCol = true;
                    this.onAllCheckChange(value);
                }}
                onCheckChange={(record, index) => {
                    this.isClickedCheckCol = true;
                    // 为了避免和新开发的行框选点选区域选事件冲突
                    if (high_selectable && high_selectable.enable &&
                        (this.hotkey == 'shift' || this.hotkey == 'ctrl' || this.hotkey == 'ctrl&shift')) {
                        this.hotkey = null;
                        return;
                    }
                    this.hotkey = null;
                    this.onCheckChange(record, index);
                }}
                checkInfo={getCheckInfo()}
                langJson={langJson}
                high_filter={{
                    alloweFilter: true,
                    langJson,
                    panelTitle: metaName || '',
                    filterData,
                    customColData,
                    rowKey,
                    isReset: store.getStore('filterReset'),
                    arrColumns,
                    handleFilterCallBack: (params = {}) => {
                        let {
                            filteredRow,
                            filteredRowKeyValues,
                            columnStatus = {},
                            // 原始过滤条件
                            filterItemList,
                            // 当前过滤条件
                            afterFilterItemList,
                            afterFilterFactors,
                        } = params;

                        // console.log(params);

                        // 更新重置状态
                        store.setStore('filterReset', false, false);

                        // 设置列状态  设置表格筛选图标
                        store.setColumn(tableId, columnStatus, false);

                        // 设置行状态
                        let data = store.getData() || [];
                        data.forEach(row => {
                            if (filteredRowKeyValues.includes(row[rowKey])) {
                                row.filter = false;
                            } else {
                                row.filter = true;
                            }
                        });

                        // 处理已选等逻辑
                        let checkInfo = store.getCheckInfo();
                        if (filteredRow && filteredRow.length) {
                            checkInfo.indeterminate = filteredRow.some(item => {
                                let values = item.values || item;
                                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                return unSaga && item.selected;
                            });
                            checkInfo.checkedAll = filteredRow.every(item => {
                                let values = item.values || item;
                                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                return !unSaga || item.selected;
                            });
                        }
                        store.setStore(["checkInfo"], checkInfo, false);

                        // 更新 所有的更新放到弹窗关闭(onHide)时
                        store.setData({ data, shouldForceUpdate: false });
                        handleFilterCallBack && handleFilterCallBack();
                    },
                    handleResetCallBack: params => {
                        let { filterItemList, columnStatus } = params;
                        // 更新重置状态
                        store.setStore('filterReset', false, false);
                        // 设置行状态
                        let data = store.getData() || [];
                        data.forEach(row => {
                            row.filter = false;
                            delete row.filterIndex;
                        });

                        // 处理已选等逻辑
                        let checkInfo = store.getCheckInfo();
                        let viewData = store.getViewData();
                        if (viewData && viewData.length) {
                            checkInfo.indeterminate = viewData.some(item => {
                                let values = item.values || item;
                                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                return unSaga && item.selected;
                            });
                            checkInfo.checkedAll = viewData.every(item => {
                                let values = item.values || item;
                                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                return !unSaga || item.selected;
                            });
                        }
                        store.setStore(["checkInfo"], checkInfo, false);

                        // 更新 所有的更新放到弹窗关闭(onHide)时
                        store.setData({ data, shouldForceUpdate: false });

                        // 列状态
                        store.setColumn(tableId, columnStatus, false);
                        handleResetCallBack && handleResetCallBack();
                    },
                    onHide: params => {
                        let { filterItemList, columnStatus } = params;
                        store.setColumn(tableId, columnStatus, true);
                        // console.log(params);
                    },
                    ...high_filter,
                }}
                high_selectable={{
                    store,
                    onSelect: (activedCells = {}, activeSpecialCells = [], hotkey, { current, isBatch, isCheckbox }) => {
                        // console.log(activedCells, hotkey, current);
                        let pervActivedCells = store.getCache('activedCells') || {};
                        for (let rowKeyValue in activedCells) {
                            pervActivedCells[rowKeyValue] = pervActivedCells[rowKeyValue] || [];
                            for (let attrcode of activedCells[rowKeyValue]) {
                                pervActivedCells[rowKeyValue].push(attrcode);
                                // 解决批改时边线样式不对的问题
                                if (isBatchSelect) {
                                    // let update = new 
                                    store.setCellProps(rowKeyValue, attrcode, {
                                        showDrag: false,
                                        actived: true,
                                        leftLine: false,
                                        rightLine: false,
                                        topLine: false,
                                        bottomLine: false,
                                    }, false)
                                    let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                                    cellObj && cellObj.setState({});
                                }
                            }
                        }

                        // 重新赋值
                        activedCells = pervActivedCells;

                        if (isBatch) {
                            this.hotkey = hotkey;
                            let rows = [];
                            for (let rowKeyValue in activedCells) {
                                let data = getStore('data');
                                // TODO
                                (activedCells[rowKeyValue].length > 0 || isCheckbox) && rows.push(data.query(rowKeyValue));
                            }
                            if (onBatchSelected) {
                                this.onBatchSelected(rows, hotkey)
                            } else {
                                setSelectedIndex([], 'clear', false);
                                rows.forEach(row => {
                                    let rowKeyValue = row[rowKey];
                                    let values = row.values || row,
                                        isSagaStatus = values && values.saga_status && values.saga_status.value === '1';
                                    // 错误行禁用
                                    if (isSagaStatus && row.disabled) {
                                        return;
                                    }

                                    let isSelected = hasSelected(rowKeyValue);

                                    !isSelected && setSelectedIndex([{ rowKey, rowKeyValue, pattern: 'add' }], 'add', false);
                                });
                            }
                        }

                        if (hotkey === 'down' && current && current.rowKeyValue && status == 'edit') {
                            store.setCellProps(current.rowKeyValue, current.attrcode, { showDrag: true }, false);
                            let cellObj = store.getCache(['cell', current.rowKeyValue + current.attrcode]);
                            cellObj && cellObj.setState({});
                        }

                        // 缓存已选
                        store.setCache('activedCells', activedCells);
                        store.setCache('activedCell', current);

                        // 没有选中单元格
                        if (!isBatchSelect) {
                            store.refresh();
                            return;
                        }

                        // 选中表头
                        let oldActiveSpecialCells = store.getCache('activeSpecialCells') || [];
                        if (activeSpecialCells.length > 0 || (activeSpecialCells.length === 0 && oldActiveSpecialCells.length !== 0)) {
                            oldActiveSpecialCells.forEach(item => {
                                // 不能用actived 会有冲突
                                store.setColumn(tableId, { [item.attrcode]: { unitActive: false } }, false);
                            });
                            activeSpecialCells.forEach(item => {
                                // 不能用actived 会有冲突
                                store.setColumn(tableId, { [item.attrcode]: { unitActive: true } }, false);
                            });
                            store.setCache('activeSpecialCells', activeSpecialCells);
                        }

                        // 修正边框
                        if (activedCells || activeSpecialCells) {
                            let arrayMeta = store.getArrayMeta(tableId, true),
                                arrayMetaLen = arrayMeta.length;
                            let viewData = store.getViewData('filter');
                            let linkObjMeta = arrayMeta.reduce((result, item, i, arr) => {
                                let next = arr[i + 1] || {};
                                let prev = arr[i - 1] || {};
                                if (item.visible) {
                                    result[item.attrcode] = {
                                        prev: prev.attrcode,
                                        current: item.attrcode,
                                        next: next.attrcode,
                                    }
                                }
                                return result;
                            }, {});

                            viewData.forEach((row, i) => {
                                let values = row.values;
                                let prevRowValues = (viewData[i - 1] || {}).values || {};
                                let nextRowValues = (viewData[i + 1] || {}).values || {};
                                let rowKeyValue = row[rowKey];

                                for (let attrcode in values) {
                                    let cell = values[attrcode];
                                    if (cell['actived']) {
                                        let prevCol = linkObjMeta[attrcode] && linkObjMeta[attrcode].prev;
                                        let nextCol = linkObjMeta[attrcode] && linkObjMeta[attrcode].next;

                                        cell['leftLine'] = !prevCol || !values[prevCol] || !values[prevCol]['actived'];

                                        cell['rightLine'] = !nextCol || !values[nextCol] || !values[nextCol]['actived'];

                                        cell['topLine'] = prevRowValues && (!prevRowValues[attrcode] || !prevRowValues[attrcode]['actived']);

                                        cell['bottomLine'] = nextRowValues && (!nextRowValues[attrcode] || !nextRowValues[attrcode]['actived']);
                                        // 单体更新
                                        let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                                        cellObj && cellObj.setState({});
                                    }
                                }
                            });

                            // console.log(linkObjMeta, viewData);
                        }
                        // 更新表格
                        if (hotkey !== 'down' && hotkey !== 'drag') {
                            store.refresh();
                        }
                    },
                    onDeSelect: (deActiveCells, hotkey) => {
                        let prevActiveCells = store.getCache('activedCells') || {};
                        let prevActiveCellsObj = {};
                        let prevActiveCellsArr = {};
                        // 转对象
                        for (let rowKeyValue in prevActiveCells) {
                            prevActiveCellsObj[rowKeyValue] = {};
                            for (let attrcode of prevActiveCells[rowKeyValue]) {
                                prevActiveCellsObj[rowKeyValue][attrcode] = true;
                            }
                        }

                        // 清理数据
                        for (let rowKeyValue in deActiveCells) {
                            for (let attrcode of deActiveCells[rowKeyValue]) {
                                if (prevActiveCellsObj[rowKeyValue][attrcode]) {
                                    delete prevActiveCellsObj[rowKeyValue][attrcode];
                                    // setCellProps(rowKeyValue, attrcode, { showDrag: false }, false);
                                    setCellProps(rowKeyValue, attrcode, {
                                        actived: false,
                                        leftLine: false,
                                        rightLine: false,
                                        topLine: false,
                                        bottomLine: false,
                                    }, false);
                                    // 单体更新
                                    let cellObj = store.getCache(['cell', rowKeyValue + attrcode]);
                                    cellObj && cellObj.setState({});
                                }
                            }
                        }

                        // 转数组
                        for (let rowKeyValue in prevActiveCellsObj) {
                            prevActiveCellsArr[rowKeyValue] = [];
                            for (let attrcode in prevActiveCellsObj[rowKeyValue]) {
                                prevActiveCellsArr[rowKeyValue].push(attrcode);
                            }
                        }

                        // 重新赋值
                        store.setCache('activedCells', prevActiveCellsArr);
                    },
                    getPrevSelector: key => {
                        const activedCells = store.getCache('activedCells') || {},
                            prevSelector = {};
                        for (let rowKeyValue in activedCells) {
                            (activedCells[rowKeyValue] || []).forEach(attrcode => {
                                prevSelector[`${rowKeyValue}@${attrcode}`] = true;
                            });
                        }
                        // console.log(activedCells, prevSelector, 'prevSelector');
                        return prevSelector;
                    },
                    onClearData: (shouldForceUpdate = false, hotkey) => {
                        store.setCache('activedCells', {});
                        store.setCache('activeSpecialCells', []);
                        // 没有选中单元格
                        if (!isBatchSelect) {
                            return;
                        }
                        // 这个方法说不定比较频繁 后面优化下

                        // let activedCells = store.getCache('activedCells') || {};
                        // let activedCell = store.getCache('activedCell');
                        let data = store.getData() || [];
                        let rowKey = store.getTableProps('rowKey');
                        data.forEach(row => {
                            let values = row.values || row || {};
                            let rowKeyValue = row[rowKey];
                            let actived = false;
                            let showDrag = false;
                            for (let attrcode in values) {
                                showDrag = values[attrcode].showDrag;
                                showDrag && setCellProps(rowKeyValue, attrcode, { showDrag: false }, false);
                                actived = values[attrcode].actived;
                                actived && setCellProps(rowKeyValue, attrcode, {
                                    actived: false,
                                    leftLine: false,
                                    rightLine: false,
                                    topLine: false,
                                    bottomLine: false,
                                }, false);
                                if (showDrag || actived) {
                                    let cell = store.getCache(['cell', rowKeyValue + attrcode]);
                                    cell && cell.setState({});
                                }
                            }
                        });

                        const columns = store.getArrayMeta(tableId, false) || [];
                        shouldForceUpdate = false;
                        columns.forEach(item => {
                            shouldForceUpdate = shouldForceUpdate || item.unitActive;
                            item.unitActive && store.setColumn(tableId, { [item.attrcode]: { unitActive: false } }, false);
                        });

                        // 暂时先这样判断, 表头被选中时再全局更新
                        if (shouldForceUpdate) {
                            store.refresh();
                        }

                    },
                    arrColumns: store.getArrayMeta(tableId, false),
                    data,
                    rowKey,
                    onBatchChange: (activeCell = {}, activeCells = {}) => {
                        // 没有选中单元格
                        if (!isBatchSelect) {
                            return;
                        }
                        // activeCell = {}, activeCells = {}
                        // 开始  批改以下所有
                        activeCell = {
                            ...activeCell,
                            // , 'normal', 'filter' 要不要转 跟rows有关系 只在内部流转
                            rowIndex: store.getTrueRowIndex({ rowKeyValue: activeCell.rowKeyValue }, 'normal', 'normal'),
                            attrcode: activeCell.attrcode,
                            column: store.getColumn(tableId, activeCell.attrcode),
                        };

                        setCellProps(activeCell.rowKeyValue, activeCell.attrcode, { showDrag: false }, false);
                        let cellObj = store.getCache(['cell', activeCell.rowKeyValue + activeCell.attrcode]);
                        cellObj && cellObj.setState({});

                        // 表格数据  是否包含删除的数据 这里要斟酌下
                        let rows = store.getData();
                        let viewData = store.getViewData();

                        let pasteContent = [];
                        let activeCellValue = getCellValue({ rowKeyValue: activeCell.rowKeyValue, attrcode: activeCell.attrcode }) || {};
                        for (let rowKeyValue in activeCells) {
                            let attrcode = activeCells[rowKeyValue][0];
                            // 做过一次radio和checkkbox转换为select 这里可能会有问题 TODO测试
                            let isDisplay = (activeCell.column.itemtype === 'refer' && activeCell.column.fieldDisplayed === 'refname') || activeCell.column.itemtype === 'select';
                            attrcode && pasteContent.push(isDisplay ? activeCellValue.display : activeCellValue.value);
                        }

                        console.log('onBatchChange trigger', activeCell, activeCells);

                        let param = {
                            pasteContent,
                            activeCell,
                            langJson,
                            rows,
                            viewData,
                            getRowOldValue: store.getRowOldValue,
                            tableId,
                            store: store,
                        }
                        let data = excelPackageData(param);
                        onBatchChange && onBatchChange(data);
                    },
                    isBatchSelect,
                    ...high_selectable,
                }}
                high_sidebox={{
                    langJson,
                    arrColumns,
                    tableId,
                    fieldid,
                    onCloseModel,
                    tableModelConfirm,
                    store,
                    sideBoxConfig,
                }}
                high_hotkey={{
                    tableId,
                    store,
                    enable: true,
                }}
                excelConfig={{ tableId, onBatchChange, langJson }}
                //折行回调
                foldingRowChange={isEllipsis => {
                    this.setState({ isEllipsis })
                }}
                foldCacheId={foldCacheId} //对于没有appcode，pagecode..的表格，做折行缓存的标志
                //右键菜单配置参数
                contextMenuConfig={{
                    enable: high_selectable && high_selectable.enable && isBatchSelect,
                    status: status,
                    contextMenuClick: this.contextMenuClick,
                    showBusinessBtn: isFunction(rightMenuClick),
                    showPasteBtn: isFunction(onBatchChange),
                    langJson: langJson,
                }}
                //滚动条悬浮
                autoAffixScroll
                //——————————操作列————————————
                colsSettingParam={colSettingParam}
                cancelCustomRightMenu={cancelCustomRightMenu}
                showWidthAndHeightConfig={showWidthAndHeightConfig}
                updateColumnHandle={this.updateColumnAfterSetting}
                // key={this.state.key}
                //——————————操作列end————————————
                //空白列
                addBlankCol={addBlankCol}
                {...others}
            />
        </div>;
    }
}

EditTable.displayName = 'EditTable';
EditTable.useEditTable = useEditTable;
