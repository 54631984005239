import React, { Component } from 'react';
import { Button } from '@platform/base';
import { getSysFieldid } from '@platform/api';
import { utils } from '@platform/table-core';
import Form from '@platform/form';
const { isFunction, isArray, isObject, isBoolean, uuidv4 } = utils;
require('./index.less');

export default class TableSideBox extends Component {
    constructor(props) {
        super(props);
        this.uuid = uuidv4() || 'editTableSideBox';
        this.state = {
            index: this.props.index,
            buttonDisabled: [],
        };
        this.formStore = Form.useForm();
    }

    componentDidMount() {
        let { columns = [], operateType, record, renderItems = {} } = this.props;
        columns = columns.filter(column => {
            // 默认设置下renderStatus
            if (column.render && column.itemtype !== 'customer') {
                column.renderStatus = column.renderStatus || 'edit';
            }
            return column.itemtype !== 'customer';
        })
        // console.log(columns);
        const meta = {
            [this.uuid]: {
                status: operateType === 'edit' || operateType === 'add' ? 'edit' : 'browse',
                items: columns,
                moduletype: "form",
                //"status":"edit",
                code: "head",
                name: "侧拉表单",
                isunfold: false,
            },
        }
        this.formStore.setMeta(meta, this.uuid);

        for (const key in renderItems) {
            this.formStore.replaceItem(key, renderItems[key]);
        }

        let formValue = record.values || {};
        this.formStore.setStatus(operateType === 'edit' || operateType === 'add' ? 'edit' : 'browse');
        this.formStore.setAllFormValue(formValue);
        console.log(this.formStore, renderItems, formValue);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let { operateType, record } = nextProps;

        let formValue = record.values || {};
        this.formStore.setStatus(operateType === 'edit' || operateType === 'add' ? 'edit' : 'browse');
        this.formStore.setAllFormValue(formValue);
        console.log(this.formStore, formValue);
    }

    render() {
        let {
            langJson = {},
            onClose,
            status = false,
            fieldid,
            operateType,
            onCancel,
            onSure,
            onAdd,
            tableId,
            record,
            onBeforeEvent,
            onAfterEvent,

            model,
            origin,
            renderItems,
            pageScope,

            value,
            scale,
            tableScope = {},
            modelIndex,
            diabled,

            edittable_dom,
        } = this.props;

        const animation = (status => {
            let usual = {
                mask: 'edit-table-modal-mask ',
                dialog: 'edit-table-modal-dialog ',
            };
            switch (status) {
                case 'origin':
                    return usual;
                case 'open':
                    usual.mask += ' fadeIn';
                    usual.dialog += ' slideInRight';
                    break;
                case 'close':
                    usual.mask += ' fadeOut';
                    usual.dialog += ' slideOutRight';
                    break;
                default:
                    break;
            }
            return usual;
        })(status);

        const mask = animation.mask;
        const dialog = animation.dialog;

        return (<section className="edit-table-modal disabled-bg-btn" fieldid={getSysFieldid(`${fieldid || tableId}_modal-area`)}>
            <div
                className={mask}
                onClick={e => {
                    onClose && onClose(e);
                }}
            />
            <div className={dialog}>
                <div className="edit-table-modal-header cf disabled-bg-btn">
                    <div
                        className="fl u-modal-title"
                        fieldid={getSysFieldid(`${operateType === 'add' ? langJson['table_add'] : langJson['table_change']}_title`)}
                    >
                        {' '}
                        {operateType === 'add' ? langJson['table_add'] : langJson['table_change']}
                    </div>
                    <span
                        className="icon iconfont icon-you icon-celashouqi fr"
                        onClick={e => {
                            onClose && onClose(e);
                        }}
                    />
                </div>
                <div className="edit-table-modal-body" fieldid={getSysFieldid(`${fieldid || tableId}_form-area`)} id="tableModal">
                    {/* 使用表单创建侧拉表头 */}
                    <div fieldid={getSysFieldid(`${tableId}_form-area`)}>
                        <Form
                            // meta={this.formMeta}
                            name={this.uuid}
                            //status={status}
                            config={{
                                onAfterEvent,
                                onBeforeEvent,
                            }}
                            store={this.formStore}
                        //init={}
                        />
                    </div>
                </div>
                <div className="edit-table-modal-footer cf" fieldid={getSysFieldid('bottom-area')}>
                    <Button
                        className=" fr"
                        onClick={e => {
                            onCancel && onCancel(e);
                        }}
                    // shape="border"
                    >
                        {langJson['table_cancel']}
                    </Button>
                    <Button
                        className="u-button-primary fr"
                        onClick={e => {
                            onSure && onSure(e);
                        }}
                    >
                        {langJson['table_ok']}
                    </Button>
                    {operateType === 'add' && (
                        <Button
                            className="u-button-primary fr"
                            onClick={(e) => {
                                onAdd && onAdd(e);
                            }}
                        >
                            {langJson['table_save_add']}
                        </Button>
                    )}
                </div>
            </div>
        </section>);
    }
}
