/*
 * @Descripttion: 侧拉弹窗
 * @version: 
 * @Author: bbq
 * @Date: 2020-09-15 16:32:08
 * @LastEditors: bbq
 * @LastEditTime: 2020-12-02 16:55:24
 */

import React, { Component, Fragment } from 'react';

import { utils, CONFIG } from '@platform/table-core';
const { isFunction } = utils;

import SideBox from './index';
const { ROW_STATUS } = CONFIG;

/**
* 弹窗逻辑处理
* @param {*} Table 
*/
export default function hotkey(Table) {
    if (!Table) {
        console.error('缺少Table组件');
    }
    return class MetaTable extends Component {
        constructor(props) {
            super(props);
            // this.state = { show: false };
        }

        //表单编辑后，保存表单数据到子表行
        onAfterEvent = prams => {
            let { attrcode, curMetaItem, value, oldValue, allValue, componentValue } = prams;

            let { high_sidebox, onAfterEvent, tableId, rowKey } = this.props;

            let { store } = high_sidebox;
            let sideBoxConfig = store.getStore('sideBoxConfig') || {};
            let { index, record = {} } = sideBoxConfig;

            record.values = allValue;

            sideBoxConfig = { ...sideBoxConfig };
            store.setStore('sideBoxConfig', sideBoxConfig, false);

            // 更新下行状态
            if (curMetaItem.itemtype !== 'residtxt') {
                // let isMulti = curMetaItem.isMultiSelectedEnabled;
                store.setCache('oldBatchData', {
                    // TODO  转换为过滤后的index
                    // 在onchange时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                    batchChangeIndex: sideBoxConfig.index,
                    batchChangeKey: curMetaItem.attrcode,
                    batchChangeValue: value.value,
                    batchChangeDisplay: value.display,
                    // batchChangeValue: isMulti ? theValue : theValue.value,
                    // batchChangeDisplay: isMulti ? null : theValue.display
                });
            } else {
                // 多语批改
                store.setCache('oldBatchData', {
                    batchChangeIndex: sideBoxConfig.index,
                    batchChangeKey: curMetaItem.attrcode,
                    batchChangeValue: value.value,
                    batchChangeDisplay: null,
                });
            }

            // 编辑关联项  放在 onAfterEvent 中

            // 把status置为1，标识修改     状态不为1的不动 (比如用户自己set但是状态为2的)
            let rowKeyValue = record[rowKey];
            let rowStatus = store.getRowProps({ rowKeyValue }, 'status');
            if (rowStatus == ROW_STATUS.origin) {
                store.setRowProps({ rowKeyValue }, { status: ROW_STATUS.edit }, false);
            }

            // 根据rowid 更新表格数据
            store.setRows({ rows: [record] });

            isFunction(onAfterEvent) &&
                onAfterEvent({
                    tableId: tableId,
                    record: record,
                    rowKey,
                    rowKeyValue,
                    rowIndex: index,
                    attrcode: attrcode,
                    value: value,
                    oldValue: oldValue,
                    componentValue: componentValue,
                    //event,
                    column: curMetaItem,
                    //triggerType
                    isTriggerModal: true,
                }, store);
        };

        onBeforeEvent = async (moudleId, attrcode, value, values, column, event) => {
            let { high_sidebox, tableId, onBeforeEvent, rowKey } = this.props;

            let { store } = high_sidebox;
            let sideBoxConfig = store.getStore('sideBoxConfig') || {};
            let { index, record = {} } = sideBoxConfig;

            // 越过hotkey 组件里的逻辑
            // event.target.ncExecuteFocus = true;

            if (isFunction(onBeforeEvent)) {
                // console.log("record", record)
                return (await onBeforeEvent({
                    tableId: tableId,
                    record: record,
                    rowKey,
                    rowKeyValue: record[rowKey],
                    rowIndex: index,
                    attrcode: attrcode,
                    value: value,
                    event,
                    column,
                    //triggerType
                    isTriggerModal: true,
                })) !== false;
            } else {
                return true;
            }
        };

        render() {
            let { high_sidebox, selectedChange, rowKey, renderItems } = this.props;
            let {
                langJson, sideBoxConfig = {}, tableId, arrColumns,
                fieldid, onCloseModel, tableModelConfirm, store,
            } = high_sidebox;
            let { index, operateType, record, show = false } = sideBoxConfig;

            // console.log(sideBoxConfig);

            return <Fragment>
                {
                    show && <SideBox
                        langJson={langJson}
                        rowKey={rowKey}
                        fieldid={fieldid}
                        columns={arrColumns}
                        renderItems={renderItems}
                        tableId={tableId}
                        onBeforeEvent={this.onBeforeEvent}
                        onAfterEvent={this.onAfterEvent}
                        onClose={e => {
                            // console.log('close');
                            let sideBoxConfig = store.getStore('sideBoxConfig') || {};
                            sideBoxConfig = { ...sideBoxConfig, show: false };
                            store.setStore('sideBoxConfig', sideBoxConfig);
                        }}
                        onCancel={e => {
                            if (operateType === 'add') {
                                const oldLen = store.getSelectedRows(false, 'filter').length;
                                store.deleteTableRows({ rowIndexs: index });
                                const newLen = store.getSelectedRows(false, 'filter').length;
                                isFunction(selectedChange) && selectedChange(tableId, newLen, oldLen)
                            } else if (operateType === 'edit') {
                                const data = store.getCache(['beforeEdit']) || { rows: [] };
                                const record = data.rows[index] || {};
                                store.updateTableDataByIndexs([{ index, data: record }]);
                            }

                            // 关闭弹窗
                            let sideBoxConfig = store.getStore('sideBoxConfig') || {};
                            sideBoxConfig = { ...sideBoxConfig, show: false, status: 'close' };
                            store.setStore('sideBoxConfig', sideBoxConfig);

                            //弹出之后，禁止后面的滚动事件
                            let html = document.getElementsByTagName('html');
                            html[0].style.overflow = '';

                            // record 就是record
                            isFunction(onCloseModel) ? onCloseModel(record) : function () {
                                console.warn('请传入关闭窗口函数');
                                return false;
                            };
                        }}
                        onSure={e => {
                            // 关闭弹窗
                            let sideBoxConfig = store.getStore('sideBoxConfig') || {};
                            sideBoxConfig = { ...sideBoxConfig, show: false, status: 'close' };
                            store.setStore('sideBoxConfig', sideBoxConfig);

                            //弹出之后，禁止后面的滚动事件
                            let html = document.getElementsByTagName('html');
                            html[0].style.overflow = '';

                            // record 就是record
                            isFunction(tableModelConfirm) ? tableModelConfirm(record) : console.warn('请传入关闭窗口函数');
                        }}
                        onAdd={e => {
                            store.removeEmptyRows({ keys: [] });
                            let sideBoxConfig = store.getStore('sideBoxConfig') || {};
                            sideBoxConfig = { ...sideBoxConfig, show: true };

                            sideBoxConfig.operateType = 'add';
                            sideBoxConfig.status = 'open';
                            let addIndex = store.getViewData().length;
                            let record = store.addTableRow(addIndex, {}, false);
                            sideBoxConfig.record = record;
                            sideBoxConfig.index = addIndex;

                            //         // 自动聚焦
                            //         if (autoFocus == true) {
                            //             let items = this.state.meta[tableId].items;
                            //             for (let i = 0; i < items.length; i++) {
                            //                 if (
                            //                     items[i].attrcode != 'numberindex' &&
                            //                     (typeof items[i].disabled == 'undefined' || items[i].disabled == false) &&
                            //                     (typeof items[i].visible != 'undefined' && items[i].visible == true)
                            //                 ) {
                            //                     rows[addIndex].values[items[i].attrcode].isEdit = true;
                            //                     break;
                            //                 }
                            //             }
                            //         }

                            store.setStore('sideBoxConfig', sideBoxConfig);
                        }}
                        {...sideBoxConfig}
                    />
                }
                <Table
                    {...this.props}
                />
            </Fragment>
        }
    }
}